import { FcMultipleDevices, FcOpenedFolder } from "react-icons/fc";
import { BsCurrencyBitcoin } from "react-icons/bs";
import classes from "./How.module.css";

const How = () => {
  return (
    <section className={classes.how}>
      <div className={classes.heading}>
        <h2>How it works</h2>
      </div>
      <div className={classes.contents}>
        <div className={classes.content}>
          <FcMultipleDevices className={classes.icon} />
          <h2>Easy registration</h2>
          <p>
            Get started by registering here on our website with few easy clicks
            for a managed futures(gold), forex and cryptocurrency account.
          </p>
        </div>
        <div className={classes.content}>
          <BsCurrencyBitcoin className={classes.icon} />
          <h2>Purchase crypto</h2>
          <p>
            All transactions between us and our clients are in cryptocurrency
            and BITCOIN precisely. To invest, you have to purchase BITCOINS from
            a trusted service like{" "}
            <a href="https://www.coinbase.com" target="_blank" rel="noreferrer">
              COINBASE
            </a>
            ,{" "}
            <a href="https://www.coinmama.com" target="_blank" rel="noreferrer">
              COINMAMA
            </a>
            ,{" "}
            <a href="https://www.binance.com" target="_blank" rel="noreferrer">
              BINANCE
            </a>
          </p>
        </div>
        <div className={classes.content}>
          <FcOpenedFolder className={classes.icon} />
          <h2>Choose a plan</h2>
          <p>
            We offer different plans which you can choose from according to the
            amount you wish to invest and the duration of contract. Choose a
            plan and transfer bitcoins to provided wallet. Investment portfolio
            is created as soon as your funds are received.
          </p>
        </div>
      </div>
    </section>
  );
};

export default How;
