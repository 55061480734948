import { Link } from "react-router-dom";

import classes from "./Footer.module.css";
import { company } from "../../config/config";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.address}>
          <h2>{company}</h2>
          <address>11 Wall St, New York, NY 10005, United States</address>
          <p>Email → service@orohandel.com</p>
          <p>Tel → +1-929-645-1958</p>
        </div>
        <nav className={classes.nav}>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
            <li>
              <Link to="/why-us">Why Us</Link>
            </li>
            <li>
              <Link to="/plans">Plans</Link>
            </li>
          </ul>
        </nav>
      </div>
      <p className={classes.copyright}>
        © {year} {company}
      </p>
    </footer>
  );
};

export default Footer;
