import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import classes from "./Welcome.module.css";
import welcomePhoto from "../../images/providence welcome.jpg";
import { company } from "../../config/config";

const Welcome = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const link = isLoggedIn ? "/plans" : "/signUp";
  return (
    <section className={classes.welcome}>
      <div className={classes.body}>
        <h1>Think digital assets, think {company}.</h1>
        <p>
          Invest in Gold, Forex and Cryptocurrencies 24/7 commission-free with
          {company}.
        </p>
        <Link to={link}>Get started</Link>
      </div>
      <div className={classes.image}>
        <img src={welcomePhoto} alt="stocks" />
      </div>
    </section>
  );
};

export default Welcome;
