import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import MenuIcon from "../icons/MenuIcon";
import classes from "./Header.module.css";
import Nav from "./Nav";
import { company } from "../../config/config";

const Header = () => {
  const menuRef = useRef();
  const navigate = useNavigate();

  const gotoHomePage = () => {
    navigate("/");
  };

  const displayMenuHandler = () => {
    menuRef.current.classList.toggle(classes.hidden);
  };

  const navClasses = `${classes.nav} ${classes.hidden}`;

  return (
    <header className={classes.container}>
      <div className={classes.header}>
        <h2 className={classes.logo} onClick={gotoHomePage}>
          {company}
        </h2>
        <div className={classes.icon} onClick={displayMenuHandler}>
          <MenuIcon />
        </div>
        <div className={navClasses} ref={menuRef}>
          <Nav />
        </div>
      </div>
      <div className={classes.currencyContainer}>
        <iframe
          src="//www.exchangerates.org.uk/widget/ER-LRTICKER.php?w=2000&s=3&mc=USD&mbg=F0F0F0&bs=yes&bc=000044&f=verdana&fs=12px&fc=000044&lc=000044&lhc=FE9A00&vc=FE9A00&vcu=008000&vcd=FF0000&"
          height="30"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          title="currency"
          className={classes.currency}
        ></iframe>
      </div>
    </header>
  );
};

export default Header;
