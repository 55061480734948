import classes from "./InFlow.module.css";

const InFlow = (props) => {
  const amount = `$${props.amount.toFixed(2)}`;
  const time = new Date(props.time).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return (
    <div className={classes.content}>
      <h2>{amount}</h2>
      <p className={classes.status}>{props.status}</p>
      <p className={classes.time}>{time}</p>
    </div>
  );
};

export default InFlow;
