import { useRef, useState, useEffect } from "react";
import useInput from "../../hooks/userInput";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import {
  HiOutlineLockClosed,
  HiOutlineUserCircle,
  HiOutlinePhoneIncoming,
} from "react-icons/hi";

import { FcGlobe } from "react-icons/fc";
import { BsFillEnvelopeAtFill } from "react-icons/bs";

import classes from "./Signup.module.css";
import { alertActions } from "../../store/alert-slice";
import { authActions } from "../../store/auth-slice";
import { signUp } from "../../api/api";
import Spinner from "../UI/Spinner";
import { company } from "../../config/config";

const Signup = () => {
  const [countries, setCountries] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const referralRef = useRef();
  const setCookie = useCookies(["jwt"])[1];
  const btnRef = useRef();
  const {
    value: firstNameInput,
    enteredValueIsValid: firstNameInputIsValid,
    hasError: firstNameInputIsInvalid,
    valueInputChangedHandler: firstNameInputChangedHandler,
    valueInputBlurHandler: firstNameInputBlurHandler,
    reset: firstNameInputReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: lastNameInput,
    enteredValueIsValid: lastNameInputIsValid,
    hasError: lastNameInputIsInvalid,
    valueInputChangedHandler: lastNameInputChangedHandler,
    valueInputBlurHandler: lastNameInputBlurHandler,
    reset: lastNameInputReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: countryInput,
    enteredValueIsValid: countryInputIsValid,
    hasError: countryInputIsInvalid,
    valueInputChangedHandler: countryInputChangedHandler,
    valueInputBlurHandler: countryInputBlurHandler,
    reset: countryInputReset,
  } = useInput((value) => value.trim() !== "Choose country");

  const {
    value: emailInput,
    enteredValueIsValid: emailInputIsValid,
    hasError: emailInputIsInvalid,
    valueInputChangedHandler: emailInputChangedHandler,
    valueInputBlurHandler: emailInputBlurHandler,
    reset: emailInputReset,
  } = useInput((value) => value.trim().includes("@"));

  const {
    value: phoneInput,
    enteredValueIsValid: phoneInputIsValid,
    hasError: phoneInputIsInvalid,
    valueInputChangedHandler: phoneInputChangedHandler,
    valueInputBlurHandler: phoneInputBlurHandler,
    reset: phoneInputReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: passwordInput,
    enteredValueIsValid: passwordInputIsValid,
    hasError: passwordInputIsInvalid,
    valueInputChangedHandler: passwordInputChangedHandler,
    valueInputBlurHandler: passwordInputBlurHandler,
    reset: passwordInputReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: confirmPasswordInput,
    enteredValueIsValid: confirmPasswordInputIsValid,
    hasError: confirmPasswordInputIsInvalid,
    valueInputChangedHandler: confirmPasswordInputChangedHandler,
    valueInputBlurHandler: confirmPasswordInputBlurHandler,
    reset: confirmPasswordInputReset,
  } = useInput((value) => value.trim() !== "");

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get("https://restcountries.com/v3.1/all");
      const data = res.data.map((el) => el.name.common);
      data.sort();
      setCountries(data);
    };
    fetchData();
  }, []);

  let formIsValid = false;
  if (
    firstNameInputIsValid &&
    lastNameInputIsValid &&
    countryInputIsValid &&
    emailInputIsValid &&
    phoneInputIsValid &&
    passwordInputIsValid &&
    confirmPasswordInputIsValid
  ) {
    formIsValid = true;
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    btnRef.current.classList.add(classes.scaleIn);
    setShowSpinner(true);

    const data = {
      name: `${firstNameInput} ${lastNameInput}`,
      email: emailInput,
      phone: phoneInput,
      country: countryInput,
      referralEmail: referralRef.current.value,
      password: passwordInput,
      passwordConfirm: confirmPasswordInput,
    };

    const res = await signUp(data);

    if (res.status === "success") {
      dispatch(authActions.login({ user: res.data.user }));
      dispatch(
        alertActions.setState({
          message: "Signed up successfully!",
          status: res.status,
        })
      );
      setCookie("jwt", res.token);
      navigate("/", { replace: true });
    } else {
      dispatch(
        alertActions.setState({ message: res.message, status: "error" })
      );
    }
    btnRef.current.classList.remove(classes.scaleIn);
    firstNameInputReset();
    lastNameInputReset();
    passwordInputReset();
    emailInputReset();
    phoneInputReset();
    countryInputReset();
    confirmPasswordInputReset();
    referralRef.current.value = "";
    setShowSpinner(false);
  };

  const firstNameInputClasses = firstNameInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const lastNameInputClasses = lastNameInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const countryInputClasses = countryInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const emailInputClasses = emailInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const phoneInputClasses = phoneInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const passwordInputClasses = passwordInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const confirmPasswordInputClasses = confirmPasswordInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  return (
    <form className={classes.form} onSubmit={submitHandler}>
      {showSpinner && <Spinner />}
      <Helmet>
        <title>Sign up to {company}</title>
        <meta
          name="description"
          content="Sign up to invest today and start reaping great benefits"
        />
        <link rel="canonical" href="/SignUp" />
      </Helmet>
      <div className={firstNameInputClasses}>
        <label>First name</label>
        <div className={classes["input-group"]}>
          <HiOutlineUserCircle className={classes.icon} />
          <input
            type="text"
            value={firstNameInput}
            onChange={firstNameInputChangedHandler}
            onBlur={firstNameInputBlurHandler}
            placeholder="First name"
          />
        </div>
        {firstNameInputIsInvalid && <span>Please enter your first name</span>}
      </div>
      <div className={lastNameInputClasses}>
        <label>Last name</label>
        <div className={classes["input-group"]}>
          <HiOutlineUserCircle className={classes.icon} />
          <input
            type="text"
            value={lastNameInput}
            onChange={lastNameInputChangedHandler}
            onBlur={lastNameInputBlurHandler}
            placeholder="Last name"
          />
        </div>
        {lastNameInputIsInvalid && <span>Please enter your last name</span>}
      </div>
      <div className={emailInputClasses}>
        <label>Email address</label>
        <div className={classes["input-group"]}>
          <BsFillEnvelopeAtFill className={classes.icon} />
          <input
            type="text"
            value={emailInput}
            onChange={emailInputChangedHandler}
            onBlur={emailInputBlurHandler}
            placeholder="you@example.com"
          />
        </div>
        {emailInputIsInvalid && <span>Please enter a valid email address</span>}
      </div>
      <div className={countryInputClasses}>
        <label>Country</label>
        <div className={classes["input-group"]}>
          <FcGlobe className={classes.icon} />
          <select
            value={countryInput}
            onChange={countryInputChangedHandler}
            onBlur={countryInputBlurHandler}
          >
            <option>Choose country</option>
            {countries?.map((el) => (
              <option key={el}>{el}</option>
            ))}
          </select>
        </div>
        {countryInputIsInvalid && <span>Please select a country</span>}
      </div>
      <div className={phoneInputClasses}>
        <label>Phone number</label>
        <div className={classes["input-group"]}>
          <HiOutlinePhoneIncoming className={classes.icon} />
          <input
            type="number"
            value={phoneInput}
            onChange={phoneInputChangedHandler}
            onBlur={phoneInputBlurHandler}
            placeholder="Phone number"
          />
        </div>
        {phoneInputIsInvalid && <span>Please enter your phone line</span>}
      </div>
      <div className={classes.group}>
        <label>Referrer's email</label>
        <div className={classes["input-group"]}>
          <BsFillEnvelopeAtFill className={classes.icon} />
          <input
            type="email"
            ref={referralRef}
            placeholder="referrer@example.com (optional)"
          />
        </div>
      </div>
      <div className={passwordInputClasses}>
        <label>Password</label>
        <div className={classes["input-group"]}>
          <HiOutlineLockClosed className={classes.icon} />
          <input
            type="password"
            value={passwordInput}
            onChange={passwordInputChangedHandler}
            onBlur={passwordInputBlurHandler}
            placeholder="password"
          />
        </div>
        {passwordInputIsInvalid && <span>Password field cannot be empty</span>}
      </div>
      <div className={confirmPasswordInputClasses}>
        <label>Confirm password</label>
        <div className={classes["input-group"]}>
          <HiOutlineLockClosed className={classes.icon} />
          <input
            type="password"
            value={confirmPasswordInput}
            onChange={confirmPasswordInputChangedHandler}
            onBlur={confirmPasswordInputBlurHandler}
            placeholder="Confirm password"
          />
        </div>
        {confirmPasswordInputIsInvalid && (
          <span>Please confirm your passowrd</span>
        )}
      </div>
      <div className={classes.action}>
        <button ref={btnRef} type="submit" disabled={!formIsValid}>
          Sign Up
        </button>
      </div>
    </form>
  );
};

export default Signup;
