import classes from "./Heading.module.css";

const Heading = () => {
  return (
    <section className={classes.heading}>
      <p>
        Our mission is to look after the financial best interests of our clients
        making sure they experience total financial freedom in their life time
        with minimal risk and zero stress.
      </p>
    </section>
  );
};

export default Heading;
