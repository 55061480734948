import classes from "./Stats.module.css";

const Stats = () => {
  return (
    <section className={classes.stats}>
      <h2 className={classes.heading}>We are proud of our statistics</h2>
      <div className={classes.container}>
        <div className={classes.stat}>
          <h1>10</h1>
          <p>Years of experience</p>
        </div>
        <div className={classes.stat}>
          <h1>39</h1>
          <p>Countries with clients</p>
        </div>
        <div className={classes.stat}>
          <h1>12</h1>
          <p>Worldwide awards</p>
        </div>
      </div>
    </section>
  );
};

export default Stats;
