import { useState } from "react";
import useInput from "../../hooks/userInput";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";

import classes from "./Password.module.css";
import { updateMyPassword } from "../../api/api";
import { alertActions } from "../../store/alert-slice";
import Spinner from "../UI/Spinner";

const Password = () => {
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const { jwt } = useCookies(["jwt"])[0];
  const {
    value: passwordInput,
    enteredValueIsValid: passwordInputIsValid,
    hasError: passwordInputIsInvalid,
    valueInputChangedHandler: passwordInputChangedHandler,
    valueInputBlurHandler: passwordInputBlurHandler,
    reset: passwordInputReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: newPasswordInput,
    enteredValueIsValid: newPasswordInputIsValid,
    hasError: newPasswordInputIsInvalid,
    valueInputChangedHandler: newPasswordInputChangedHandler,
    valueInputBlurHandler: newPasswordInputBlurHandler,
    reset: newPasswordInputReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: confirmPasswordInput,
    enteredValueIsValid: confirmPasswordInputIsValid,
    hasError: confirmPasswordInputIsInvalid,
    valueInputChangedHandler: confirmPasswordInputChangedHandler,
    valueInputBlurHandler: confirmPasswordInputBlurHandler,
    reset: confirmPasswordInputReset,
  } = useInput((value) => value.trim() !== "");

  let formIsValid = false;
  if (
    passwordInputIsValid &&
    newPasswordInputIsValid &&
    confirmPasswordInputIsValid
  ) {
    formIsValid = true;
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const data = {
      passwordCurrent: passwordInput,
      password: newPasswordInput,
      passwordConfirm: confirmPasswordInput,
    };

    const res = await updateMyPassword(data, jwt);

    if (res.status === "success") {
      dispatch(
        alertActions.setState({
          message: "Password changed successfully!",
          status: res.status,
        })
      );
    } else {
      dispatch(
        alertActions.setState({ message: res.message, status: "error" })
      );
    }

    passwordInputReset();
    newPasswordInputReset();
    confirmPasswordInputReset();
    setShowSpinner(false);
  };

  const passwordInputClasses = passwordInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const newPasswordInputClasses = newPasswordInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const confirmPasswordInputClasses = confirmPasswordInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  return (
    <form className={classes.form} onSubmit={submitHandler}>
      {showSpinner && <Spinner />}
      <div className={passwordInputClasses}>
        <label>Old password</label>
        <input
          type="password"
          value={passwordInput}
          onChange={passwordInputChangedHandler}
          onBlur={passwordInputBlurHandler}
        />
      </div>
      <div className={newPasswordInputClasses}>
        <label>New password</label>
        <input
          type="password"
          value={newPasswordInput}
          onChange={newPasswordInputChangedHandler}
          onBlur={newPasswordInputBlurHandler}
        />
      </div>
      <div className={confirmPasswordInputClasses}>
        <label>Confirm New password</label>
        <input
          type="password"
          value={confirmPasswordInput}
          onChange={confirmPasswordInputChangedHandler}
          onBlur={confirmPasswordInputBlurHandler}
        />
      </div>
      <div className={classes.action}>
        <button type="submit" disabled={!formIsValid}>
          Proceed
        </button>
      </div>
    </form>
  );
};

export default Password;
