import { Helmet } from "react-helmet-async";

import classes from "./Why.module.css";
import { company } from "../../config/config";

const Why = () => {
  return (
    <section className={classes.why}>
      <Helmet>
        <title>Why {company}</title>
        <meta
          name="description"
          content="Smart investors take note. Our performance results speak for themselves and can be viewed 24/7 for you to verify."
        />
        <link rel="canonical" href="/why-us" />
      </Helmet>
      <div className={classes.paragraphs}>
        <p>
          We provide gold and cryptocurrency portfolios with clients looking to
          access the managed accounts space in a meaningful way. That has been
          our distinctive feature for years now, coupled with our experienced
          team up to the challenge of finding unique managers to fit distinctive
          needs.
        </p>
        <p>
          Trading on the markets can potentially provide those with the required
          skill access to significant returns which attract tons of people to
          trading every year.
        </p>
        <p>
          {company} provide a solution for investors with little or no knowledge
          about the financial market benefit from its high volatility and yield.
          We put you forward with a fully variable structure to access the
          optimum managed trading service available.
        </p>
        <p>
          Our managed trading service has achieved returns of over 20% per month
          for investors and the team behind {company} includes institutional
          traders and expert traders with over 25 years worth of combined
          experience. Therefore, by choosing to have your trading account
          managed by {company}, you will gain access to any of the trading
          strategies we have available.
        </p>
        <p>
          Also you will benefit from our expertise in the markets and our years
          of experience of connecting investors to trading strategies that seeks
          substantial returns.
        </p>
        <p>
          Our team comprises of seasoned professionals with decades of
          experience in the gold market with a proven track record of
          consistently delivering between 8-12% monthly returns, even in
          turbulent market.
        </p>
        <p>Here at {company} , we are reliable ,trustworthy and transparent.</p>
      </div>
      <div className={classes.cards}>
        <div className={classes.card}>
          <h2>Our Experience</h2>
          <p>
            {company} has achieved returns of over 20% per month for investors
            who have accessed the managed trading service. With over 25 years
            combined trading experience the {company} trading team aims to
            maximize profits for investors in a compliant and regulated
            environment.
          </p>
        </div>
        <div className={classes.card}>
          <h2>100% Transparency</h2>
          <p>
            Smart investors take note. Our performance results speak for
            themselves and can be viewed 24/7 for you to verify.
          </p>
        </div>
        <div className={classes.card}>
          <h2>Risk Management</h2>
          <p>
            You can select the amount of capital you are willing to risk for
            this type of investment, and we will make sure that your capital is
            secured while you make profit.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Why;
