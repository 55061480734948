import { Link } from "react-router-dom";
import classes from "./OurJob.module.css";
// import investment from "../../images/tech-daily-ztYmIQecyH4-unsplash.jpg";
// import retirement from "../../images/retirement.jpg";
// import charges from "../../images/charges.jpg";

import gold from "../../images/gold.jpg";
import currency from "../../images/currency.jpg";
import crypto from "../../images/crypto.jpg";

const OurJob = () => {
  return (
    <section className={classes.job}>
      <div className={classes.title}>
        <h2>Our Task</h2>
        <p>
          We specialize in a trifecta of trading domains: gold, cryptocurrency,
          and traditional currencies. This diversified approach allows us to
          harness opportunities across various financial landscapes.
        </p>
        <Link to="/about-us">Learn more</Link>
      </div>
      <div className={classes.tasks}>
        <div className={classes.task}>
          <div className={classes.image}>
            <img src={gold} alt="investment" />
          </div>
          <h2>Gold</h2>
          <p>
            Gold, known for its intrinsic value, has been a sought-after asset
            for centuries. It’s considered a ‘safe-haven’ investment, often
            inversely related to stock market volatility and economic downturns.
          </p>
        </div>
        <div className={classes.task}>
          <div className={classes.image}>
            <img src={crypto} alt="retirement" />
          </div>
          <h2>Cryptocurrency</h2>
          <p>
            Cryptocurrencies are digital or virtual currencies that use
            cryptography for security and operate independently of a central
            authority or traditional banking system.
          </p>
        </div>
        <div className={classes.task}>
          <div className={classes.image}>
            <img src={currency} alt="charges" />
          </div>
          <h2>Currency(Forex) trade</h2>
          <p>
            Forex, short for foreign exchange, is the global marketplace for
            trading national currencies against one another.
          </p>
        </div>
      </div>
    </section>
  );
};

export default OurJob;
