import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

import AboutUs from "../aboutUs/AboutUs";
// import Disclaimer from "../aboutUs/Disclaimer";
import Heading from "../aboutUs/Heading";
import Team from "../aboutUs/Team";
import { company } from "../../config/config";

const About = () => {
  return (
    <Fragment>
      <Helmet>
        <title>About {company}</title>
        <meta
          name="description"
          content="Our mission is to look after the financial best interests of our clients making sure they experience total financial freedom in their life time"
        />
        <link rel="canonical" href="/about-us" />
      </Helmet>
      <AnimationOnScroll animateIn="animate__bounceIn" initiallyVisible="true">
        <Heading />
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__bounceIn" initiallyVisible="true">
        <AboutUs />
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__bounceIn" initiallyVisible="true">
        <Team />
      </AnimationOnScroll>
      {/* <AnimationOnScroll animateIn="animate__bounceIn" initiallyVisible="true">
        <Disclaimer />
      </AnimationOnScroll> */}
    </Fragment>
  );
};

export default About;
