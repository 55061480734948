import React from "react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

import How from "../body/How";
import OurJob from "../body/OurJob";
import Partners from "../body/Partners";
import Plans from "../body/Plans";
import Secure from "../body/Secure";
import Stats from "../body/Stats";
import Welcome from "../body/Welcome";
import { company } from "../../config/config";

const Home = () => {
  return (
    <Fragment>
      <Helmet>
        <title>{company}</title>
        <meta
          name="description"
          content="Welcome to Orohandel - empowering individuals and businesses to achieve financial success. Explore our investment solutions and let us guide you to a prosperous future"
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <AnimationOnScroll animateIn="animate__bounceIn" initiallyVisible="true">
        <Welcome />
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__bounceIn">
        <Plans />
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__bounceIn" initiallyVisible="true">
        <OurJob />
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__bounceIn">
        <Secure />
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__bounceIn">
        <How />
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__bounceIn">
        <Stats />
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__bounceIn">
        <Partners />
      </AnimationOnScroll>
    </Fragment>
  );
};

export default Home;
