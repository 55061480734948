import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import Subscription from './Subscription';
import classes from './Subscriptions.module.css';
import { getUser } from '../../api/api';

const Subscriptions = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [subs, setSubs] = useState([1]);
    const cookie = new Cookies();
    const jwt = cookie.get('jwt');

    useEffect(() => {
        const request = async () => {
            const res = await getUser(jwt);

            if (res.status === 'success') {
                setSubs(res.data.user.subscription);
                setIsLoading(false);
            }
        };
        request();
    }, [jwt]);

    if (subs.length === 0) {
        return <p className={classes.empty}>You have not made any investments yet. Please choose a plan and invest.</p>;
    }

    return (
        <div className={classes.subs}>
            {isLoading && subs.map((sub) => <Skeleton key={sub} variant="rectangular" width="40rem" height="60rem" />)}
            {!isLoading &&
                subs.map((sub) => (
                    <Subscription
                        key={sub._id}
                        id={sub._id}
                        name={sub.plan.name}
                        invested={sub.amount}
                        profit={(sub.amount / 100) * sub.plan.percentage}
                        balance={sub.updatedAmount}
                        userId={sub.user._id}
                        expiryDate={sub.expiryDate}
                        investmentDate={sub.createdAt}
                        contractStatus={
                            sub.active && new Date(Date.now()).getTime() < new Date(sub.expiryDate).getTime()
                                ? 'Active'
                                : !sub.active && new Date(Date.now()).getTime() < new Date(sub.expiryDate).getTime()
                                ? 'Pending'
                                : 'Completed'
                        }
                    />
                ))}
        </div>
    );
};

export default Subscriptions;
