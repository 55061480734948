import classes from "./AboutUs.module.css";
import { company } from "../../config/config";

const AboutUs = () => {
  return (
    <section className={classes.aboutUs}>
      <h2>More about us</h2>
      <p>
        {company} provides high return financial trading services with
        unparalleled features for the clients utilizing recent technological
        innovations and responding to the ever higher investor expectations. We
        have put the most emphasis on safety, on balanced performance and on
        customer support. We provide services which approach investment from the
        natural demands of the clients. Through our highly efficient portfolio
        management you significantly reduce the high risk of losses associated
        with trading financial instruments and focus more on the unparalleled
        benefits derivable therefrom.
      </p>
      <p>
        The basis of the investment come from the possibilities offered by the
        exchange markets, especially by the currency exchange market; with us,
        you too may profit from these possibilities and achieve returns that
        have only been available to institutional investors before now.
      </p>
      <p>
        We are an asset management company. For developing our services first we
        have examined the needs and desires of the investors, and found that the
        following aspects are the most important: safety, high returns,
        transparent operation, liquidity, professional expertise and low costs.
      </p>
      <p>
        From the first day our aim has not changed: we currently trade on gold
        and cryptocurrency for our clients which meet their expectations and
        desires completely. We are proud of what we have achieved until today,
        our success is proven by the positive responses we continuously get from
        our ever growing client base. As an asset management company we focus on
        working with strictly professional team of traders who are able to
        provide a good performance and stable results for our investors in the
        long term.
      </p>
      <p>
        Our account management team has been specializing in exploiting the
        different market conditions across many years. The fluctuations of the
        currency market has got a direct effect on the performance of the
        accounts, but if the total amount invested is diversified in equal
        proportions, the targeted profit can be achieved in a more stable
        manner. We put great emphasis on providing high returns to our clients
        regardless of global economics. By holding an investment with us you can
        enjoy the benefits of the cryptocurrency and gold market without
        economic expertise, time and efforts.
      </p>
    </section>
  );
};

export default AboutUs;
