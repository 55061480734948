import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import classes from './Nav.module.css';
import { signOut } from '../../api/api';
import { authActions } from '../../store/auth-slice';

const Nav = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const setCookie = useCookies(['jwt'])[1];

    const logoutHandler = async () => {
        const res = await signOut();
        if (res.status === 'success') {
            setCookie('jwt', res.token);
        }
        dispatch(authActions.logout());
        navigate('/login', { replace: true });
    };

    return (
        <nav className={classes.nav}>
            <ul>
                <li>
                    <NavLink to="/" className={(navData) => (navData.isActive ? classes.active : '')}>
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/about-us" className={(navData) => (navData.isActive ? classes.active : '')}>
                        About Us
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/contact-us" className={(navData) => (navData.isActive ? classes.active : '')}>
                        Contact Us
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/why-us" className={(navData) => (navData.isActive ? classes.active : '')}>
                        Why Us
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/plans" className={(navData) => (navData.isActive ? classes.active : '')}>
                        Plans
                    </NavLink>
                </li>
                <li>
                    {!isLoggedIn && (
                        <NavLink to="/login" className={(navData) => (navData.isActive ? classes.active : '')}>
                            Login
                        </NavLink>
                    )}
                    {isLoggedIn && (
                        <NavLink
                            to="/login"
                            className={(navData) => (navData.isActive ? classes.active : '')}
                            onClick={logoutHandler}
                        >
                            Logout
                        </NavLink>
                    )}
                </li>
                <li>
                    {!isLoggedIn && (
                        <NavLink to="/signUp" className={(navData) => (navData.isActive ? classes.active : '')}>
                            Get started
                        </NavLink>
                    )}
                    {isLoggedIn && (
                        <NavLink to="/dashboard" className={(navData) => (navData.isActive ? classes.active : '')}>
                            Dashboard
                        </NavLink>
                    )}
                </li>
            </ul>
        </nav>
    );
};

export default Nav;
