import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../main/Footer";
// import ReactWhatsapp from "react-whatsapp";

import Header from "../main/Header";
// import Whatsapp from "../UI/Whatsapp";
// import classes from "../UI/General.module.css";

const UserLayout = () => {
  return (
    <Fragment>
      <Header />
      <main>
        <Outlet />
      </main>
      {/* <ReactWhatsapp number="+1-929-645-1958" className={classes.whatsapp}>
        <Whatsapp />
      </ReactWhatsapp> */}
      <Footer />
    </Fragment>
  );
};

export default UserLayout;
