import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import classes from "./Plan.module.css";
import { subActions } from "../../store/subscription-slice";
// import { slideActions } from "../../store/slide-slice";

const Plan = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const amountRange = `$${props.amount}`;
  const returns = `${props.profit}% Weekly Returns`;
  console.log(props);

  const navigateHandler = () => {
    navigate("/signUp");
  };

  const setSubHandler = () => {
    const data = {
      plan: props.id,
      profit: returns,
      range: amountRange,
      withdrawal: "weekly Withdrawals",
      name: props.name,
    };
    dispatch(subActions.setData({ data }));
    // dispatch(slideActions.showSlide());
    navigate("/prePayment");
  };

  return (
    <div className={classes.content}>
      <h2>{props.name}</h2>
      <h3>{amountRange}</h3>
      <p>{returns}</p>
      <p>{props.contractLength} Months Contract</p>
      <p>Enhanced Security</p>
      <p>Weekly Withdrawals</p>
      <p>24/7 Support</p>
      <button
        type="button"
        onClick={isLoggedIn ? setSubHandler : navigateHandler}
      >
        Invest now
      </button>
    </div>
  );
};

export default Plan;
