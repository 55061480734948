import classes from "./Team.module.css";
import tessa from "../../images/harvey.jpeg";
import ben from "../../images/ben.jpeg";
import john from "../../images/john.jpeg";
import ziva from "../../images/Ziva.jpeg";
import donna from "../../images/donna.jpeg";
import nathalie from "../../images/natalie.jpeg";
import dario from "../../images/dario.jpeg";
import nonie from "../../images/nonie.jpeg";
import deven from "../../images/Deven.jpeg";
import gareth from "../../images/gareth.jpeg";
import derek from "../../images/derek.jpeg";

const Team = () => {
  return (
    <section className={classes.team}>
      <div className={classes.heading}>
        <h2>We're dedicated humans</h2>
        <p>committed to growing wealth for anyone</p>
      </div>
      <div className={classes.members}>
        <div className={classes.member}>
          <div className={classes.image}>
            <img src={tessa} alt="Tessa" />
          </div>
          <h2>Tessa Harvey</h2>
          <p>Founder</p>
        </div>
        <div className={classes.member}>
          <div className={classes.image}>
            <img src={ben} alt="Ben" />
          </div>
          <h2>Ben Hughes</h2>
          <p>Chief Administrative Officer</p>
        </div>
        <div className={classes.member}>
          <div className={classes.image}>
            <img src={john} alt="John" />
          </div>
          <h2>John S. Schnabel</h2>
          <p>Managing Director</p>
        </div>
        <div className={classes.member}>
          <div className={classes.image}>
            <img src={ziva} alt="Ziva" />
          </div>
          <h2>Ziva Cornwell</h2>
          <p>Chief Financial Officer</p>
        </div>
        <div className={classes.member}>
          <div className={classes.image}>
            <img src={donna} alt="Donna" />
          </div>
          <h2>Donna R. Hurley</h2>
          <p>Business analyst</p>
        </div>
        <div className={classes.member}>
          <div className={classes.image}>
            <img src={nathalie} alt="Nathalie" />
          </div>
          <h2>Nathalie Harris</h2>
          <p>Chief Data Scientist</p>
        </div>
        <div className={classes.member}>
          <div className={classes.image}>
            <img src={dario} alt="Dario" />
          </div>
          <h2>Dario Salvatore</h2>
          <p>Chief Operating Officer</p>
        </div>
        <div className={classes.member}>
          <div className={classes.image}>
            <img src={nonie} alt="Nonie" />
          </div>
          <h2>Nonie Myerson</h2>
          <p>Executive Vice President</p>
        </div>
        <div className={classes.member}>
          <div className={classes.image}>
            <img src={deven} alt="Deven Gallagher" />
          </div>
          <h2>Deven Gallagher</h2>
          <p>Private Clients Services</p>
        </div>
        <div className={classes.member}>
          <div className={classes.image}>
            <img src={derek} alt="Derek Gorman" />
          </div>
          <h2>Derek Gorman</h2>
          <p>Chief Information Officer</p>
        </div>
        <div className={classes.member}>
          <div className={classes.image}>
            <img src={gareth} alt="Gareth Ruse" />
          </div>
          <h2>Gareth Ruse</h2>
          <p>Chief Marketing consultant</p>
        </div>
      </div>
    </section>
  );
};

export default Team;
