import { NavLink } from "react-router-dom";

import classes from "./DashNav.module.css";

const DashNav = () => {
  return (
    <nav className={classes.nav}>
      <ul>
        <li>
          <NavLink
            to="/dashboard/subscriptions"
            className={(navData) => (navData.isActive ? classes.active : "")}
          >
            Investments
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/dashboard/deposits"
            className={(navData) => (navData.isActive ? classes.active : "")}
          >
            Deposits
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/dashboard/withdrawls"
            className={(navData) => (navData.isActive ? classes.active : "")}
          >
            Withdrawals
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/dashboard/password"
            className={(navData) => (navData.isActive ? classes.active : "")}
          >
            Password
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/dashboard/bonus"
            className={(navData) => (navData.isActive ? classes.active : "")}
          >
            Bonus
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default DashNav;
