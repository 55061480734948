import { useState, useEffect } from 'react';
import useInput from '../../hooks/userInput';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';

import classes from './Subscription.module.css';
import { withdraw } from '../../api/api';
import { alertActions } from '../../store/alert-slice';
import { spinnerActions } from '../../store/spinner-slice';
import { depositActions } from '../../store/deposit-slice';
import { slideActions } from '../../store/slide-slice';

const Subscription = (props) => {
    const dispatch = useDispatch();
    const [showWarning, setShowWarning] = useState(false);
    const { jwt } = useCookies(['jwt'])[0];
    const invested = `$${props.invested.toFixed(2)}`;
    const profit = `$${props.profit.toFixed(2)}`;
    const balance = `$${props.balance.toFixed(2)}`;
    const expiryDate = new Date(props.expiryDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });
    const investmentDate = new Date(props.investmentDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });
    const showActions = props.contractStatus === 'Active' ? true : false;
    const contractStatusColor =
        props.contractStatus === 'Active' ? 'green' : props.contractStatus === 'Completed' ? '#495057' : 'yellow';

    useEffect(() => {
        if (new Date(Date.now()).getTime() > new Date(expiryDate).getTime()) setShowWarning(true);
    }, [expiryDate]);

    const {
        value: withdrawInput,
        enteredValueIsValid: withdrawInputIsValid,
        hasError: withdrawInputIsInvalid,
        valueInputChangedHandler: withdrawInputChangedHandler,
        valueInputBlurHandler: withdrawInputBlurHandler,
        reset: withdrawInputReset,
    } = useInput((value) => value.trim() !== '');

    const {
        value: walletInput,
        enteredValueIsValid: walletInputIsValid,
        hasError: walletInputIsInvalid,
        valueInputChangedHandler: walletInputChangedHandler,
        valueInputBlurHandler: walletInputBlurHandler,
        reset: walletInputReset,
    } = useInput((value) => value.trim() !== '');

    const {
        value: depositInput,
        enteredValueIsValid: depositInputIsValid,
        hasError: depositInputIsInvalid,
        valueInputChangedHandler: depositInputChangedHandler,
        valueInputBlurHandler: depositInputBlurHandler,
        reset: depositInputReset,
    } = useInput((value) => value.trim() !== '');

    let withdrawFormIsValid = false;
    if (withdrawInputIsValid && walletInputIsValid) {
        withdrawFormIsValid = true;
    }

    let depositFormIsValid = false;
    if (depositInputIsValid) {
        depositFormIsValid = true;
    }

    const withdrawHandler = async (e) => {
        e.preventDefault();
        dispatch(spinnerActions.showSpinner());

        const data = {
            amount: withdrawInput,
            subscription: props.id,
            user: props.userId,
            wallet: walletInput,
        };

        const res = await withdraw(data, jwt);

        if (res.status === 'success') {
            dispatch(alertActions.setState({ message: res.message, status: res.status }));
        } else {
            dispatch(alertActions.setState({ message: res.message, status: 'error' }));
        }

        withdrawInputReset();
        walletInputReset();
        dispatch(spinnerActions.hideSpinner());
    };

    const depositHandler = (e) => {
        e.preventDefault();

        const data = {
            amount: depositInput,
            subscription: props.id,
            user: props.userId,
        };

        dispatch(depositActions.setDepositData({ data }));

        depositInputReset();
        dispatch(slideActions.showDepoSlide());
    };

    const withdrawFormClasses =
        withdrawInputIsInvalid && walletInputIsInvalid ? `${classes.info} ${classes.invalid}` : classes.info;

    const depositFormClasses = depositInputIsInvalid ? `${classes.info} ${classes.invalid}` : classes.info;

    return (
        <section className={classes.subscriptions}>
            <div className={classes.info}>
                <span>Plan</span>
                <span>{props.name}</span>
            </div>
            <div className={classes.info}>
                <span>Invested</span>
                <span>{invested}</span>
            </div>
            <div className={classes.info}>
                <span>Weekly returns</span>
                <span>{profit}</span>
            </div>
            <div className={classes.info}>
                <span>Contract length</span>
                <span>12 months</span>
            </div>
            <div className={classes.info}>
                <span>Contract status</span>
                <span
                    style={{
                        backgroundColor: contractStatusColor,
                    }}
                    className={classes.status}
                >
                    {props.contractStatus}
                </span>
            </div>
            <div className={classes.info}>
                <span>Contract Starts</span>
                <span>{investmentDate}</span>
            </div>
            <div className={classes.info}>
                <span>Contract ends</span>
                <span>{expiryDate}</span>
            </div>
            <div className={classes.info}>
                <span>Balance</span>
                <span>{balance}</span>
            </div>
            {showActions && (
                <form className={withdrawFormClasses} onSubmit={withdrawHandler}>
                    <button type="submit" disabled={!withdrawFormIsValid}>
                        Withdraw
                    </button>
                    <div className={classes.container}>
                        <input
                            type="number"
                            placeholder="Amount"
                            value={withdrawInput}
                            onChange={withdrawInputChangedHandler}
                            onBlur={withdrawInputBlurHandler}
                        />
                        <input
                            type="text"
                            placeholder="BTC wallet address"
                            value={walletInput}
                            onChange={walletInputChangedHandler}
                            onBlur={walletInputBlurHandler}
                        />
                    </div>
                </form>
            )}
            {showActions && (
                <form className={depositFormClasses} onSubmit={depositHandler}>
                    <button type="submit" disabled={!depositFormIsValid}>
                        Deposit
                    </button>
                    <input
                        type="number"
                        placeholder="Amount in usd"
                        value={depositInput}
                        onChange={depositInputChangedHandler}
                        onBlur={depositInputBlurHandler}
                    />
                </form>
            )}
            {showWarning && (
                <p className={classes.warning}>
                    Please remember that if you do not withdraw your invested capital and returns after 90days from
                    expiry date, we will proceed to forward your capital and earnings to our selected charity
                    organization.
                    <br />
                    Contact Customer support to claim your capital and earnings.
                </p>
            )}
        </section>
    );
};

export default Subscription;
