import axios from "axios";

// const axiosInstance = axios.create({
//   baseURL: "http://127.0.0.1:5000/api/v1/",
// });

const axiosInstance = axios.create({
  baseURL: "https://api.orohandel.com/api/v1/",
});

export const signIn = async (data) => {
  try {
    const res = await axiosInstance({
      method: "post",
      url: "users/login",
      data,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const signUp = async (data) => {
  try {
    const res = await axiosInstance({
      method: "post",
      url: "users/signUp",
      data,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const signOut = async () => {
  try {
    const res = await axiosInstance({
      method: "POST",
      url: "users/logout",
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const forgotPassword = async (data) => {
  try {
    const res = await axiosInstance({
      method: "post",
      url: "users/forgotPassword",
      data,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const resetPassword = async (data, token) => {
  try {
    const res = await axiosInstance({
      method: "PATCH",
      url: `users/resetPassword/${token}`,
      data,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getUser = async (jwt) => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: `users/me`,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const withdraw = async (data, jwt) => {
  try {
    const res = await axiosInstance({
      method: "post",
      url: "withdrawals",
      data,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getPlans = async () => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: `plans`,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const invest = async (data, jwt) => {
  try {
    const res = await axiosInstance({
      method: "post",
      url: "subscriptions",
      data,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const deposit = async (data, jwt) => {
  try {
    const res = await axiosInstance({
      method: "post",
      url: "deposits",
      data,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getBonus = async (jwt) => {
  try {
    const res = await axiosInstance({
      method: "GET",
      url: "bonus/getUserBonusPlan",
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const withdrawBonus = async (jwt, data) => {
  try {
    const res = await axiosInstance({
      method: "POST",
      url: "bonus-withdraw",
      data,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const updateMyPassword = async (data, jwt) => {
  try {
    const res = await axiosInstance({
      method: "PATCH",
      url: "users/updatePassword",
      data,
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
