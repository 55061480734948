import { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import Plan from './Plan';
import classes from './Plans.module.css';
import { getPlans } from '../../api/api';

const Plans = () => {
    const [plans, setPlans] = useState([1, 2]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const request = async () => {
            const res = await getPlans();
            if (res.status === 'success') {
                setPlans(res.data.plans);
                setLoading(false);
            }
        };
        request();
    }, []);

    return (
        <section className={classes.plans}>
            <div className={classes.heading}>
                <h2>Simple, transparent plans</h2>
                <p>
                    Rather than surprise fees, we bundle our investments plans into pocket friendly tiers that support
                    your financial wellness.
                </p>
            </div>
            <div className={classes.contents}>
                {loading && plans.map((el) => <Skeleton key={el} variant="rectangular" width="30rem" height="60rem" />)}
                {!loading &&
                    plans.map((plan) => (
                        <Plan
                            key={plan._id}
                            id={plan._id}
                            name={plan.name}
                            amount={plan.amount}
                            profit={plan.percentage}
                            contractLength={plan.contractLength}
                        />
                    ))}
            </div>
        </section>
    );
};

export default Plans;
