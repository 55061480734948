import { createSlice } from "@reduxjs/toolkit";

const initialState = { subData: null };

const subSlice = createSlice({
  name: "Subscription",
  initialState,
  reducers: {
    setData(state, action) {
      state.subData = action.payload.data;
    },
    resetData(state) {
      state.subData = null;
    },
  },
});

export const subActions = subSlice.actions;

export default subSlice;
