import Cookies from "universal-cookie";
import { useLoaderData } from "react-router-dom";

import Withdrawal from "./Withdrawal";
import classes from "./Withdrawals.module.css";
import { getUser } from "../../api/api";

const Withdrawals = () => {
  const res = useLoaderData();
  const withdraws = res.data.user.withdraws;

  if (withdraws.length === 0) {
    return (
      <p className={classes.empty}>You have not made any withdrawals yet.</p>
    );
  }

  return (
    <div className={classes.content}>
      {withdraws.map((data) => (
        <Withdrawal
          key={data._id}
          amount={data.amount}
          status={data.active === true ? "Successful" : "Pending"}
          wallet={data.wallet}
          time={data.createdAt}
        />
      ))}
    </div>
  );
};

export default Withdrawals;

export const loader = () => {
  const cookie = new Cookies();
  const jwt = cookie.get("jwt");

  return getUser(jwt);
};
