import Cookies from "universal-cookie";
import { useLoaderData } from "react-router-dom";

import classes from "./Deposits.module.css";
import InFlow from "./InFlow";
import { getUser } from "../../api/api";

const Deposits = () => {
  const res = useLoaderData();

  const deposits = res.data.user.deposits;

  if (deposits.length === 0) {
    return (
      <p className={classes.empty}>You have not made any new deposits yet.</p>
    );
  }

  return (
    <div className={classes.content}>
      {deposits.map((deposit) => (
        <InFlow
          key={deposit._id}
          amount={deposit.amount}
          status={deposit.active === true ? "Successful" : "Pending"}
          time={deposit.createdAt}
        />
      ))}
    </div>
  );
};

export default Deposits;

export const loader = () => {
  const cookie = new Cookies();
  const jwt = cookie.get("jwt");

  return getUser(jwt);
};
