import { createSlice } from "@reduxjs/toolkit";

const initialState = { depositData: null };

const depositSlice = createSlice({
  name: "Deposit",
  initialState,
  reducers: {
    setDepositData(state, action) {
      state.depositData = action.payload.data;
    },
    resetDepositData(state) {
      state.depositData = null;
    },
  },
});

export const depositActions = depositSlice.actions;

export default depositSlice;
