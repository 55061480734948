import { useState } from "react";
import useInput from "../../hooks/userInput";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import classes from "./SubForm.module.css";
import wallet from "../../images/wallet.JPG";
import { invest } from "../../api/api";
import { alertActions } from "../../store/alert-slice";
import Spinner from "../UI/Spinner";

const SubForm = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();
  const { jwt } = useCookies(["jwt"])[0];
  const subData = useSelector((state) => state.subscription.subData);
  const userId = useSelector((state) => state.auth.user._id);
  const navigate = useNavigate();

  const {
    value: amountInput,
    enteredValueIsValid: amountInputIsValid,
    hasError: amountInputIsInvalid,
    valueInputChangedHandler: amountInputChangedHandler,
    valueInputBlurHandler: amountInputBlurHandler,
    reset: amountInputReset,
  } = useInput((value) => value.trim() !== "");

  let formIsValid = false;
  if (amountInputIsValid) {
    formIsValid = true;
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const data = {
      amount: amountInput,
      plan: subData.plan,
      user: userId,
    };

    const res = await invest(data, jwt);

    if (res.status === "success") {
      dispatch(
        alertActions.setState({ message: res.message, status: res.status })
      );
      navigate("/dashboard");
    } else {
      dispatch(
        alertActions.setState({ message: res.message, status: "error" })
      );
    }

    amountInputReset();
    setShowSpinner(false);
  };

  const formClasses = amountInputIsInvalid
    ? `${classes.form} ${classes.invalid}`
    : classes.form;

  return (
    <div className={classes.sub}>
      {showSpinner && <Spinner />}
      <div className={classes.data}>
        <span>Plan name</span>
        <span>{subData.name}</span>
      </div>
      <div className={classes.data}>
        <span>Amount range</span>
        <span>{subData.range}</span>
      </div>
      <div className={classes.data}>
        <span>Withdrawals</span>
        <span>{subData.withdrawal}</span>
      </div>
      <div className={classes.data}>
        <span>Returns</span>
        <span>{subData.profit}</span>
      </div>
      <div className={classes.wallet}>
        <h2>
          Please transfer the amount you want to invest to the wallet address
          below.
        </h2>
        <div className={classes.image}>
          <img src={wallet} alt="wallet" />
        </div>
      </div>
      <form className={formClasses} onSubmit={submitHandler}>
        <label>
          Please enter the amount you transferred to our wallet in usd and
          proceed.
        </label>
        <input
          type="number"
          value={amountInput}
          onChange={amountInputChangedHandler}
          onBlur={amountInputBlurHandler}
        />
        <button type="submit" disabled={!formIsValid}>
          Proceed
        </button>
      </form>
    </div>
  );
};

// const SubForm = () => {
//   const backdropEl = document.getElementById("backdrop-root");
//   const overlayEl = document.getElementById("overlay-root");

//   return (
//     <Fragment>
//       {ReactDOM.createPortal(<Backdrop />, backdropEl)}
//       {ReactDOM.createPortal(<Form />, overlayEl)}
//     </Fragment>
//   );
// };

export default SubForm;
