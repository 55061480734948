import { useRef, useState } from "react";
import useInput from "../../hooks/userInput";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { HiAtSymbol, HiOutlineLockClosed } from "react-icons/hi";

import classes from "./Login.module.css";
import { signIn } from "../../api/api";
import { authActions } from "../../store/auth-slice";
import { alertActions } from "../../store/alert-slice";
import Spinner from "../UI/Spinner";

const Login = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();
  const setCookies = useCookies(["jwt"])[1];
  const navigate = useNavigate();
  const btnRef = useRef();
  const {
    value: emailInput,
    enteredValueIsValid: emailInputIsValid,
    hasError: emailInputIsInvalid,
    valueInputChangedHandler: emailInputChangedHandler,
    valueInputBlurHandler: emailInputBlurHandler,
    reset: emailInputReset,
  } = useInput((value) => value.trim().includes("@"));

  const {
    value: passwordInput,
    enteredValueIsValid: passwordInputIsValid,
    hasError: passwordInputIsInvalid,
    valueInputChangedHandler: passwordInputChangedHandler,
    valueInputBlurHandler: passwordInputBlurHandler,
    reset: passwordInputReset,
  } = useInput((value) => value.trim() !== "");

  let formIsValid = false;

  if (emailInputIsValid && passwordInputIsValid) {
    formIsValid = true;
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    btnRef.current.classList.add(classes.scaleIn);
    setShowSpinner(true);

    const data = {
      email: emailInput,
      password: passwordInput,
    };

    const res = await signIn(data);

    if (res.status === "success") {
      dispatch(
        alertActions.setState({
          message: "Signed in successfully!",
          status: res.status,
        })
      );
      dispatch(authActions.login({ user: res.data.user }));
      setCookies("jwt", res.token);
      navigate("/dashboard", { replace: true });
    } else {
      dispatch(
        alertActions.setState({ message: res.message, status: "error" })
      );
    }

    emailInputReset();
    passwordInputReset();
    setShowSpinner(false);
    btnRef.current.classList.remove(classes.scaleIn);
  };

  const emailInputClasses = emailInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const passwordInputClasses = passwordInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  return (
    <form className={classes.form} onSubmit={submitHandler}>
      {showSpinner && <Spinner />}
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Login to view your dashboard" />
        <link rel="canonical" href="/login" />
      </Helmet>
      <div className={emailInputClasses}>
        <label>
          <HiAtSymbol className={classes.icon} />
        </label>
        <input
          type="text"
          value={emailInput}
          onChange={emailInputChangedHandler}
          onBlur={emailInputBlurHandler}
          placeholder="you@example.com"
        />
        {emailInputIsInvalid && <span>Please enter a valid email address</span>}
      </div>
      <div className={passwordInputClasses}>
        <label>
          <HiOutlineLockClosed className={classes.icon} />
        </label>
        <input
          type="password"
          value={passwordInput}
          onChange={passwordInputChangedHandler}
          onBlur={passwordInputBlurHandler}
          placeholder="*****************"
        />
        <Link to="/forgotPassword">Forgot password?</Link>
        {passwordInputIsInvalid && <span>Password field cannot be empty</span>}
      </div>

      <div className={classes.action}>
        <button ref={btnRef} type="submit" disabled={!formIsValid}>
          Login
        </button>
      </div>
    </form>
  );
};

export default Login;
