import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import Cookies from "universal-cookie";
import useInput from "../../hooks/userInput";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";

import classes from "./Bonus.module.css";
import { getBonus } from "../../api/api";
import Spinner from "../UI/Spinner";
import { withdrawBonus } from "../../api/api";
import { alertActions } from "../../store/alert-slice";

const Bonus = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const res = useLoaderData();
  const { jwt } = useCookies(["jwt"])[0];
  const dispatch = useDispatch();
  const {
    value: walletInput,
    enteredValueIsValid: walletInputIsValid,
    valueInputChangedHandler: walletInputChangedHandler,
    valueInputBlurHandler: walletInputBlurHandler,
    reset: walletInputReset,
  } = useInput((value) => value.trim() !== "");
  if (!res.data.bonusPlan) {
    return <h2>You have no bonus</h2>;
  }
  const bonus = res.data.bonusPlan;
  const balance = bonus.amount.toFixed(2);
  const expiryDate = new Date(bonus.expiryDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  let formIsValid = false;
  if (walletInputIsValid) {
    formIsValid = true;
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setShowSpinner(true);
    const res = await withdrawBonus(jwt, { wallet: walletInput });

    if (res.status === "success") {
      dispatch(
        alertActions.setState({ message: res.message, status: res.status })
      );
    } else {
      dispatch(
        alertActions.setState({ message: res.message, status: "error" })
      );
    }

    setShowSpinner(false);
    walletInputReset();
  };
  return (
    <div className={classes.bonus}>
      {showSpinner && <Spinner />}
      <div className={classes.balance}>
        <span>Bonus balance</span>
        <span>{balance}</span>
      </div>
      <div className={classes.date}>
        <span>Expiry date</span>
        <span>{expiryDate}</span>
      </div>
      <form className={classes.form} onSubmit={submitHandler}>
        <h2>Enter your BTC wallet below to withdraw your bonus</h2>
        <div className={classes.group}>
          <label>Wallet</label>
          <input
            type="text"
            value={walletInput}
            onChange={walletInputChangedHandler}
            onBlur={walletInputBlurHandler}
          />
        </div>
        <div className={classes.action}>
          <button type="submit" disabled={!formIsValid}>
            Withdraw
          </button>
        </div>
      </form>
    </div>
  );
};

export default Bonus;

export const loader = () => {
  const cookies = new Cookies();
  const jwt = cookies.get("jwt");

  return getBonus(jwt);
};
