import { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

import classes from "./DepositForm.module.css";
import styles from "../UI/General.module.css";
import Backdrop from "../UI/Backdrop";
import wallet from "../../images/wallet.JPG";
import { slideActions } from "../../store/slide-slice";
import Spinner from "../UI/Spinner";
import { deposit } from "../../api/api";
import { alertActions } from "../../store/alert-slice";

const Overlay = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();
  const { jwt } = useCookies(["jwt"])[0];
  const depositData = useSelector((state) => state.deposit.depositData);
  const depoSlide = useSelector((state) => state.slide.depoSlide);
  const amount = `$${depositData.amount}`;

  const closeModalHandler = () => {
    dispatch(slideActions.hideDepoSlide());
  };

  const createDepositReqHandler = async () => {
    setShowSpinner(true);

    const res = await deposit(depositData, jwt);
    if (res.status === "success") {
      dispatch(
        alertActions.setState({ message: res.message, status: res.status })
      );
      dispatch(slideActions.hideDepoSlide());
    } else {
      dispatch(
        alertActions.setState({ message: res.message, status: "error" })
      );
    }

    setShowSpinner(false);
  };

  const depositClasses = depoSlide
    ? `${classes.deposit} ${styles.add}`
    : `${classes.deposit} ${styles.remove}`;

  return (
    <div className={depositClasses}>
      {showSpinner && <Spinner />}
      <div className={classes.data}>
        <span>Amount</span>
        <span>{amount}</span>
      </div>
      <div className={classes.wallet}>
        <h2>
          Please deposit {amount} to the below bitcoin address and click
          proceed.
        </h2>
        <div className={classes.image}>
          <img src={wallet} alt="wallet address" />
        </div>
      </div>
      <div className={classes.action}>
        <button type="button" onClick={createDepositReqHandler}>
          Proceed
        </button>
      </div>
      <button
        className={classes.close}
        type="button"
        onClick={closeModalHandler}
      >
        X
      </button>
    </div>
  );
};

const Deposit = () => {
  const backdropEl = document.getElementById("backdrop-root");
  const overlayEl = document.getElementById("overlay-root");

  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop />, backdropEl)}
      {ReactDOM.createPortal(<Overlay />, overlayEl)}
    </Fragment>
  );
};

export default Deposit;
