import { Link } from "react-router-dom";

import classes from "./Secure.module.css";
import Key from "../icons/Key";

const Secure = () => {
  return (
    <section className={classes.secure}>
      <Key />
      <div className={classes.text}>
        <h2>Strong security</h2>
        <p>
          Cold storage and multi-cluster system architecture, full data
          encryption and anti-DDoS protection
        </p>
        <Link to="/why-us">Learn more</Link>
      </div>
    </section>
  );
};

export default Secure;
